@import "colors";
@import "css_reset";
@import "highlighting";

html, body
{
    width: 100%;
    height: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: $text-main;
    line-height: 1.5em;
    font-size: 1.1em;
}

p
{
    margin: 1.0em 0em;
}

a:link, a:visited
{
    color: $link;
    text-decoration: none;
}
a:hover
{
    color: $link-hover;
    text-decoration: none;
}
a:active
{
    color: $link-hover;
    text-decoration: underline;
}

ul
{
    margin-left: 1.5em;
    list-style-type: circle;
}

ol
{
    margin-left: 3em;
    list-style-type: decimal;
}

li
{
    margin: 0.25em 0em;
}

strong
{
    font-weight: bold;
}

em
{
    font-style: italic;
}

code {
    font-family: monospace, monospace;
    color: #0087d7;
}

blockquote
{
    padding: 0em 0.75em;
    border-left: 4px solid $text-faint;
    border-radius: 2px;
    max-width: 100%;
    color: $text-mid;
    font-style: italic;
}

sup
{
    font-size: 0.75em;
    vertical-align: super;
}

sup[id*=fnref] a:before {
    content: "[";
}

sup[id*=fnref] a:after {
    content: "]";
}

.wrapper
{
    margin: 0 auto;
    max-width: 50em;
    padding: 0em 5em;
}

.site-header
{
    border-bottom: 1px solid $text-faint;

    .wrapper
    {
        padding: 2em 5em;
    }

    h1
    {
        font-size: 2.2em;
    }

    .tagline
    {
        color: $text-mid;
        font-style: italic;
    }

    .about-link
    {
        float: right;
    }

    .spheniscograph {
        height: 1.8em;
        margin-right: 1em;
        float: left;
    }
}

.site-footer {
    text-align: right;
    margin-top: 2em;
}

.content
{
    min-height: 50%;

    h1, h2, h3, h4
    {
        margin: 1em 0em 1em 0em;
        font-weight: bold;
        line-height: 1em;
    }

    h1
    {
        font-size: 2em;
    }

    h2
    {
        font-size: 1.5em;
        margin-top: 2em;
    }

    h3
    {
        font-size: 1.25em;
        margin-top: 2em;
    }

    h4
    {
        font-size: 1.15em;
        margin-top: 2em;
    }

    .datestamp {
        color: $text-faint;
        font-size: 0.75em;
    }

    .projectlink
    {
        font-size: 0.75em;
    }
}

.site-footer
{
    border-top: 1px solid $text-faint;
}

.post-list
{
    margin-left: 1em;
    list-style-type: none;

    .post-item
    {
        .datestamp {
            position: absolute;
            left: 0;
        }

        a {
            font-weight: bold;
        }

        h3 {
            font-size: 1.25em;
            margin: 0em;
        }

        position: relative;
        margin: 0.25em 0em;
        padding-left: 5em;
    }
}

.post
{
    img
    {
        max-width: 100%;
        max-height: 30em;
        margin: 0 auto;
        display: block;

    }

    video {
        max-width: 100%;
        max-height: 512px;
        margin: 0 auto;
        display: block;
    }

    a {
      text-decoration: underline;
    }
}

.kofi {
    text-align: center;
}

.footnotes
{
    border-top: 1px solid $text-faint;
}
