$syntax-not-defined-yet: rgb(0, 255, 0);

$syntax-normal: #585858;
$syntax-preproc: #bb4da3;
$syntax-identifier: #0087d7;
$syntax-keyword: #8800dd;
$syntax-operator: #8800dd;
$syntax-constant: #45a239;
$syntax-comment: #db8413;
$syntax-type: #86c1e3;


div.highlight, figure.highlight
{
    padding: 0em 0.75em;
    border: 1px solid $text-faint;
    border-left: 4px solid $text-faint;
    border-radius: 0px 8px 8px 0px;
    max-width: 100%;
    overflow-x: scroll;
    font-size: 0.9em;
}

.highlight
{
    pre
    {
        font-family: monospace;

        .n { color: $syntax-normal; }
        .p { color: $syntax-normal; }
        .ge  { font-style: italic } /* Generic.Emph */
        .gs  { font-weight: bold } /* Generic.Strong */
        .nl  { color: $syntax-not-defined-yet; } /* Name.Label */
        .c   { color: $syntax-comment; } /* Comment */
        .err { color: $syntax-operator; } /* Error */
        .k   { color: $syntax-keyword; } /* Keyword */
        .ch  { color: $syntax-comment; } /* Comment.Hashbang */
        .cm  { color: $syntax-comment; } /* Comment.Multiline */
        .cp  { color: $syntax-comment; } /* Comment.Preproc */
        .cpf { color: $syntax-comment; } /* Comment.PreprocFile */
        .c1  { color: $syntax-comment; } /* Comment.Single */
        .cs  { color: $syntax-comment; } /* Comment.Special */
        .gd  { color: $syntax-not-defined-yet; } /* Generic.Deleted */
        .gr  { color: $syntax-not-defined-yet; } /* Generic.Error */
        .gh  { color: $syntax-not-defined-yet; } /* Generic.Heading */
        .gi  { color: $syntax-not-defined-yet; } /* Generic.Inserted */
        .go  { color: $syntax-not-defined-yet; } /* Generic.Output */
        .gp  { color: $syntax-not-defined-yet; } /* Generic.Prompt */
        .gu  { color: $syntax-not-defined-yet; } /* Generic.Subheading */
        .gt  { color: $syntax-not-defined-yet; } /* Generic.Traceback */
        .kc  { color: $syntax-not-defined-yet; } /* Keyword.Constant */
        .kd  { color: $syntax-not-defined-yet; } /* Keyword.Declaration */
        .kn  { color: $syntax-keyword; } /* Keyword.Namespace */
        .kp  { color: $syntax-not-defined-yet; } /* Keyword.Pseudo */
        .kr  { color: $syntax-not-defined-yet; } /* Keyword.Reserved */
        .kt  { color: $syntax-type; } /* Keyword.Type */
        .m   { color: $syntax-not-defined-yet; } /* Literal.Number */
        .s   { color: $syntax-constant; } /* Literal.String */
        .na  { color: $syntax-not-defined-yet; } /* Name.Attribute */
        .nb  { color: $syntax-type; } /* Name.Builtin */
        .nc  { color: $syntax-type; } /* Name.Class */
        .no  { color: $syntax-not-defined-yet; } /* Name.Constant */
        .nd  { color: $syntax-preproc; } /* Name.Decorator */
        .ni  { color: $syntax-not-defined-yet; } /* Name.Entity */
        .nf  { color: $syntax-identifier; } /* Name.Function */
        .nn  { color: $syntax-preproc; } /* Name.Namespace */
        .nt  { color: $syntax-not-defined-yet; } /* Name.Tag */
        .nv  { color: $syntax-operator; } /* Name.Variable */
        .o   { color: $syntax-operator; } /* Operator */
        .ow  { color: $syntax-operator; } /* Operator.Word */
        .w   { color: $syntax-not-defined-yet; } /* Text.Whitespace */
        .mb  { color: $syntax-not-defined-yet; } /* Literal.Number.Bin */
        .mf  { color: $syntax-constant; } /* Literal.Number.Float */
        .mh  { color: $syntax-not-defined-yet; } /* Literal.Number.Hex */
        .mi  { color: $syntax-constant; } /* Literal.Number.Integer */
        .mo  { color: $syntax-constant; } /* Literal.Number.Oct */
        .sa  { color: $syntax-constant; } /* Literal.String.Affix */
        .sb  { color: $syntax-not-defined-yet; } /* Literal.String.Backtick */
        .sc  { color: $syntax-not-defined-yet; } /* Literal.String.Char */
        .dl  { color: $syntax-not-defined-yet; } /* Literal.String.Delimiter */
        .sd  { color: $syntax-not-defined-yet; } /* Literal.String.Doc */
        .s2  { color: $syntax-not-defined-yet; } /* Literal.String.Double */
        .se  { color: $syntax-not-defined-yet; } /* Literal.String.Escape */
        .sh  { color: $syntax-not-defined-yet; } /* Literal.String.Heredoc */
        .si  { color: $syntax-preproc; } /* Literal.String.Interpol */
        .sx  { color: $syntax-not-defined-yet; } /* Literal.String.Other */
        .sr  { color: $syntax-not-defined-yet; } /* Literal.String.Regex */
        .s1  { color: $syntax-not-defined-yet; } /* Literal.String.Single */
        .ss  { color: $syntax-not-defined-yet; } /* Literal.String.Symbol */
        .bp  { color: $syntax-type; } /* Name.Builtin.Pseudo */
        .fm  { color: $syntax-not-defined-yet; } /* Name.Function.Magic */
        .vc  { color: $syntax-not-defined-yet; } /* Name.Variable.Class */
        .vg  { color: $syntax-not-defined-yet; } /* Name.Variable.Global */
        .vi  { color: $syntax-not-defined-yet; } /* Name.Variable.Instance */
        .vm  { color: $syntax-not-defined-yet; } /* Name.Variable.Magic */
        .il  { color: $syntax-not-defined-yet; } /* Literal.Number.Integer.Long */
    }
}
